import App from '@components/App';
import Storage from 'grab-storage';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import '@duxton-web/inter';
import './globalStyles.less';
window.Grab = {
    config: {},
    storage: Storage,
};
function renderApp() {
    const container = document.getElementById('root');
    if (!container)
        return;
    const root = createRoot(container);
    root.render(React.createElement(BrowserRouter, null,
        React.createElement(App, null)));
}
renderApp();
