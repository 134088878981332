import Spinner from '@components/Spinner';
import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
const ExternalRedirect = lazy(() => import('@components/ExternalRedirect'));
const RedirectToOpen = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/open', { replace: true }); // Redirect to the '/open' route
    }, [navigate]);
    return null;
};
const App = () => (React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
    React.createElement(Routes, null,
        React.createElement(Route, { path: "/open", element: React.createElement(ExternalRedirect, { to: "https://grab.onelink.me/2695613898" }) }),
        React.createElement(Route, { path: "/", element: React.createElement(RedirectToOpen, null) }))));
export default App;
